import React from 'react';
import Card from 'react-bootstrap/Card';
import AudioCard from 'audiocard';
import styles from './EpisodeCard.module.css';

import './EpisodeCard.module.css';

const EpisodeCard = ({ episodeKey, episode, useOverlay, episodeClickHandler }) => (
  <div key={episodeKey} onClick={(event) => episodeClickHandler(episode, event)} >
    <div className={useOverlay ? styles.overlay : null}>
      <Card border='dark' bg='light' className={'mb-4'} style={{ marginInline: '2.5rem' }}>
        <Card.Body>
          <Card.Title className='font-weight-light'>{episode.title.substring(episode.title.indexOf("|") + 1)}</Card.Title>
          <AudioCard 
            title={episode.title}
            art={episode.logo}
            source={episode.media_url}
            skipBackSeconds={10}
            skipForwardSeconds={30}
          />
        </Card.Body>
      </Card>
    </div>
  </div>
);

export default EpisodeCard;