/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { fetchPodcastEpisodes } from '../services/podbeanApi';
import EmailModal from './EmailModal';
import Cookies from 'js-cookie';
import EpisodeCard from './EpisodeCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const PodcastList = () => {
  const [episodes, setEpisodes] = useState([]);
  const [moreEpisodesExist, setMoreEpisodesExist] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [emailCaptured, setEmailCaptured] = useState(false);

  useEffect(() => {
    fetchPodcastEpisodes(0).then(data => {
      setEpisodes(data.episodes);
      setMoreEpisodesExist(data.has_more);
    });
  }, []);

  const onCloseModal = () => {
    setIsModalOpen(false);

    const emailSubmitted = Cookies.get('EMAIL_SUBMITTED');
    if (emailSubmitted === "true") {
      setEmailCaptured(true);
    }
  }

  const playEpisode = (episodeId) => {
    console.log(`Now playing episode ${episodeId}.`);
  };

  const episodeClickHandler = (episode, event) => {
    event.preventDefault();
    event.stopPropagation();

    setSelectedEpisode(episode);

    // TODO this is buggy and I'm having to enter my email multiple times even though I should have the cookie set
    /*const emailSubmitted = Cookies.get('EMAIL_SUBMITTED');

    if (emailSubmitted === "true") {
      setEmailCaptured(true);
    } else {
      setSelectedEpisode(episode);
      setIsModalOpen(true);
    }
    */
  };

  const handleShowMoreEpisodes = () => {
    fetchPodcastEpisodes(episodes.length).then(data => {
      const combinedEpisodes = [...episodes, ...data.episodes];
      setEpisodes(combinedEpisodes);
      setMoreEpisodesExist(data.has_more);
    });

  }

  const getShowMoreEpisodesButton = () => {
    return (
      <Row style={{justifyContent: 'center'}}>
        <Button 
          style={{maxWidth: '85%', marginBottom: '2rem'}}
          variant="dark" 
          size="lg"
          onClick={handleShowMoreEpisodes}
          >Show More Episodes</Button>
      </Row>
    );
  }

  return (
    <Container fluid="md">
      {episodes.map((episode, index) => (
        <Row>
          <EpisodeCard 
            episodeKey={index} 
            episode={episode} 
            episodeClickHandler={episodeClickHandler} 
            //useOverlay={!emailCaptured}/>
            // TODO fix the email capture thing later
            useOverlay={false}/>
        </Row>
      ))}
      
      {moreEpisodesExist ? getShowMoreEpisodesButton() : null}

      <EmailModal 
        isOpen={isModalOpen} 
        onRequestClose={() => onCloseModal()} 
        playEpisode={() => selectedEpisode && playEpisode(selectedEpisode.id)}
      />
    </Container>
  );
};

export default PodcastList;