import React from 'react';
import PodcastList from '../components/PodcastList';
import Banner from '../components/Banner';

function Home() {
  return (
    <div>
      
        <Banner/>
      
      <h1>Podcast Episodes</h1>
      <PodcastList />
      {/* Show modal logic here, if needed */}
    </div>
  );
}

export default Home;