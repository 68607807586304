import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Cookies from 'js-cookie';

const EmailModal = ({ isOpen, onRequestClose, playEpisode }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        "Authorization": `Bearer ${process.env.REACT_APP_SENDER_NET_API_KEY}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
      };

      const body = {
        "email": email
      };
      await axios.post('https://api.sender.net/v2/subscribers', body, {
        headers: headers
      });
      Cookies.set('EMAIL_SUBMITTED', true, { expires: 365 });
      onRequestClose();
      playEpisode();
    } catch (error) {
      console.error('Failed to subscribe email:', error);
    }
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Email to Listen to Episode</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <Form.Text className="text-muted">
              P.S We dont spam and our emails are awesome!
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EmailModal;