import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import LogoTransparent from '../images/bangLogo-removed-bg.png';
import './Header.css';
import { SocialIcon } from 'react-social-icons';

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="black" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            src={LogoTransparent}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Bang Two Three"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
          <Nav className='justify-content-end'>
          
            <Nav.Link href="/appearance">Appearance Info</Nav.Link>
            <Nav.Link href="/blog">Blog</Nav.Link>
            <Nav.Link href="/contact">Contact Me</Nav.Link>
            <Nav.Link target="_blank" href="https://twitter.com/bangtwothree">
              <SocialIcon target="_blank" url="https://twitter.com/bangtwothree" style={{width: '1.5rem', height: '1.5rem'}}/>
            </Nav.Link>
            <Nav.Link target="_blank" href="https://www.instagram.com/bangtwothree">
              <SocialIcon target="_blank" url="https://www.instagram.com/bangtwothree" style={{width: '1.5rem', height: '1.5rem'}}/>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;