import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import styles from './Banner.module.css';
import PodcastProviderIcon from './PodcastProviderIcon';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const Banner = () => (
    <div className={styles.mainBannerWrapper}>
        <img alt='Banner' src="../images/banner.png"></img>
        <Container fluid="md">
            <Row className= {styles.bannerRow + ' align-items-center'}>
                <div className={styles.headingContainer} >
                    <h2 className={styles.bannerHeading}>Bang Two Three Podcast</h2>
                    <p className={styles.text}>Open conversations held as if it's the last day on earth</p>
                </div>
            </Row>
            <Row className= {styles.bannerRow + ' align-items-center'}>
                <div className={styles.contactUsContainer} >
                    <p className={styles.text}>Interested in Coming on the Show?</p>
                    <Link to="/appearance" className="nav-link">
                        <Button variant="light" size="lg">
                            Learn More
                        </Button>
                    </Link>
                </div>
            </Row>
            <Row className= {styles.bannerRow + ' align-items-center'}>
                <div className={styles.listenOnContainer}>
                    <p className={styles.listenOnText}>Listen On: </p> 
                    <PodcastProviderIcon 
                        url='https://podcasts.apple.com/us/podcast/bang-two-three-podcast/id1647999314'
                        tooltipValue='Apple Podcasts'
                        imageSource='https://d8g345wuhgd7e.cloudfront.net/site/images/admin5/apple-podcast.png'
                    />
                    <PodcastProviderIcon 
                        url='https://podcasts.google.com/feed/aHR0cHM6Ly93d3cuYmFuZ3R3b3RocmVlLmNvbS9mZWVkLnhtbA'
                        tooltipValue='Google Podcasts'
                        imageSource='https://d8g345wuhgd7e.cloudfront.net/site/images/admin5/google-podcast.png'
                    />
                    <PodcastProviderIcon 
                        url='https://open.spotify.com/show/5SjnYaxiWgVUeJPilKIuVK'
                        tooltipValue='Spotify'
                        imageSource='https://d8g345wuhgd7e.cloudfront.net/site/images/admin5/spotify.png'
                    />
                    <PodcastProviderIcon 
                        url='https://player.fm/series/3400905'
                        tooltipValue='PlayerFM'
                        imageSource='https://d8g345wuhgd7e.cloudfront.net/site/images/admin5/PlayerFM.png'
                    />                    
                    <PodcastProviderIcon 
                        url='https://music.amazon.com/podcasts/392b4bdd-7094-4846-bca6-1a6964552f4c'
                        tooltipValue='Amazon Music'
                        imageSource='https://d8g345wuhgd7e.cloudfront.net/site/images/admin5/AmazonMusic.png'
                    />
                    <PodcastProviderIcon 
                        url='https://iheart.com/podcast/102718654'
                        tooltipValue='iHeartRadio'
                        imageSource='https://d8g345wuhgd7e.cloudfront.net/site/images/admin5/iHeartRadio.png'
                    />
                </div>
            </Row>
        </Container>
    </div>
);

export default Banner;