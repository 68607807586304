import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const [state, setState] = React.useState({ email: "", message: "" });
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showFailure, setShowFailure] = React.useState(false);
  let navigate = useNavigate();

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  function wait(time) {
    return new Promise(resolve => {
      setTimeout(resolve, time);
    });
  }

  async function handleSubmissionSuccess() {
    setShowSuccess(true);
    await wait(2500);
    navigate("/"); 
  }

  async function handleSubmissionFailure() {
    setShowFailure(true)
    await wait(3500);
    navigate("/"); 
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-me", ...state })
    })
      .then(() => handleSubmissionSuccess())
      .catch(error => handleSubmissionFailure());

    e.preventDefault();
  };

  const handleChange = e => setState({ ...state, [e.target.name]: e.target.value });

  return (
    <Container fluid="md" style={{marginTop: '3rem'}}>
      {showSuccess && <Alert key='success' variant='success'>
        Thanks for your message!  Redirecting you to the home page...
      </Alert>
      }
      {showFailure && <Alert key='danger' variant='danger'>
        There was an error sending your message.  Please try again later.  Redirecting you to the home page...
      </Alert>
      }
      <h3 style={{marginBottom: '3rem'}}>Contact Me</h3>
      {/*<iframe title='Contact form' src="https://docs.google.com/forms/d/e/1FAIpQLSdh3esd7JaWYWC6yzVtr7ElNHRzC6UPSoT3Zkg0bxEpTHKojg/viewform?embedded=true" width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>*/}
      <form 
        onSubmit={handleSubmit} name="contact-me" method="POST" data-netlify="true" netlify-honeypot="bot-field" class="" action="/">
        <input type="hidden" name="form-name" value="contact-me" />
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control required type="email" placeholder="Enter email" name="email" onChange={handleChange} />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Message</Form.Label>
          <Form.Control required as="textarea" aria-label="Message..." name="message" onChange={handleChange} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </form>
    </Container>
  );
}

export default Contact;