import React from 'react';

const PodcastAppearance = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <iframe title='Contact form' src="https://docs.google.com/forms/d/e/1FAIpQLSdwH8p3BitIEIqf1Y7aTOhsi_PgIoK7Ct8XputeVTP2pclF1A/viewform?embedded=true" width="100%" height="1400" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
}

export default PodcastAppearance;