import React from 'react';
import Image from 'react-bootstrap/Image';
import BlogComingSoon from '../images/blogComingSoon.png';

function Blog() {
  return (
    <div style={{textAlign: 'center'}}>
      <Image style={{height: '500px', maxWidth: '75%', paddingTop: '4rem'}} src={BlogComingSoon} fluid />
    </div>
  );
}

export default Blog;