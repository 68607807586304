import axios from 'axios';
import Cookies from 'js-cookie';


const getAccessToken = async () => {
  const existingToken = Cookies.get('ACCESS_TOKEN');
  if (existingToken) {
    return existingToken;
  }

  const headers = {
    'Access-Control-Allow-Methods': "GET, PUT, POST, DELETE, HEAD, OPTIONS",
    'Access-Control-Allow-Origin': '*',
    'Accept': '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': 'Basic ' + process.env.REACT_APP_PODBEAN_AUTH      
  }
  
  try {
    const response = await axios.post('https://still-waters-86785-3bb992404516.herokuapp.com/https://api.podbean.com/v1/oauth/token', {
      grant_type: 'client_credentials'
    }, {headers: headers});

    const { access_token } = response.data;
    Cookies.set('ACCESS_TOKEN', access_token, { expires: 2 });
    return access_token;
  } catch (error) {
    console.error('Failed to fetch access token', error);
    throw error;
  }
};

export const fetchPodcastEpisodes = async (offset) => {
  try {
    let accessToken = await getAccessToken();
    return await axios.get(`https://still-waters-86785-3bb992404516.herokuapp.com/https://api.podbean.com/v1/episodes?access_token=${accessToken}&offset=${offset}`)
        .then((response) => response.data);
  } catch (error) {

    console.error('Failed to fetch podcast episodes', error);
    Cookies.remove('ACCESS_TOKEN');
    console.log('Removed cookie, trying again...');
    let accessToken = await getAccessToken();
    return await axios.get(`https://still-waters-86785-3bb992404516.herokuapp.com/https://api.podbean.com/v1/episodes?access_token=${accessToken}`)
        .then((response) => response.data);
  }
};