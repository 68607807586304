import React from 'react';
import styles from './PodcastProviderIcon.module.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const tooltip = (value) => (
    <Tooltip id="tooltip">
      <strong>{value}</strong>
    </Tooltip>
  );

const PodcastProviderIcon = ({url, tooltipValue, imageSource}) => (
  <div className={styles.podcastProviderImgContainer}>
    <OverlayTrigger placement="top" overlay={tooltip(tooltipValue)}>
    <a href={url} target="_blank" rel="noreferrer noopener" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title={tooltipValue} aria-label={tooltipValue}>
        <img className={styles.podcastProviderImg} src={imageSource} alt={tooltipValue}></img>
    </a>
    </OverlayTrigger>
  </div>
);

export default PodcastProviderIcon;