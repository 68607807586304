import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";
import Header from './components/Header';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import PodcastAppearance from './pages/PodcastAppearance';
import ReactGA from 'react-ga4';

function App() {
  ReactGA.initialize("G-V2N819S4M2");
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
  return (
    <Provider store={store}>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/appearance" element={<PodcastAppearance />} />
        </Routes>
      </div>
    </Provider>
  );
}

export default App;